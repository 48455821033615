@keyframes slideInLeft {
    0% {
        transform:  translateX(100vw);
    }
    45% {
        opacity: 0;
    }
    80% {
        opacity: 0.2;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes showLinks {
    0% {
        width: 51px;
    }
    50% {
        width: 250px;
    }
    100% {
        width: 250px;
    }
}

@keyframes showText {
    0% {
        opacity: 0;
        height: 0;
    }
    50% {
        opacity: 0;
        height: 0;
        width: 100%;
    }
    100% {
        opacity: 1;
        height: 30px;
        width: 100%;
    }
}

@keyframes moreMargin {
    0% {
        margin: 0;
    }
    50% {
        margin: 0;
    }
    100% {
        margin: 15px 0 0 0;
    }
}

@keyframes hideLinks {
    0% {
        width: 250px;
    }
    50% {
        width: 250px;
    }
    100% {
        width: 51px;
    }
}

@keyframes hideText {
    0% {
        opacity: 1;
        height: 30px;
        width: 100%;
    }
    50% {
        opacity: 0;
        height: 0;
        width: 100%;
    }
    100% {
        opacity: 0;
        height: 0;
        width: 0;
    }
}

@keyframes lessMargin {
    0% {
        margin: 15px 0 0 0;
    }
    100% {
        margin: 0;
    }
}