// Textpic and Textmedia

.textmedia,
.textpic {
    display: flex;
    flex-wrap: wrap;

    .textpic-gallery {
        order: 1;
    }

    .textpic-text {
        order: 2;
    }

    @include media-breakpoint-up(md) {
        &-right {
            .textpic-gallery {
                order: 2;
            }

            .textpic-text {
                order: 1;
            }
        }
    }
}

.textmedia-item,
.textpic-item {
    order: 1;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.textmedia-text,
.textpic-text {
    > *:last-child {
        margin-bottom: 0;
    }
}

.textmedia-gallery,
.textpic-gallery {
    margin-bottom: $spacer;
}

.textmedia-left,
.textpic-left {
    .textmedia-gallery,
    .textpic-gallery {
        order: 2;

        @include media-breakpoint-up(md) {
            order: 1;
        }
    }
}

// - Alignments

.textmedia-below,
.textpic-below {
    .textmedia-text,
    .textpic-text {
        margin-bottom: $spacer;
    }
    .textmedia-gallery,
    .textpic-gallery {
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(sm) {
    .textmedia-text,
    .textpic-text {
        text-align: center;
    }
}

@include media-breakpoint-up(md) {
    .textmedia-right,
    .textmedia-left,
    .textpic-right,
    .textpic-left {
        .textmedia-item,
        .textpic-item {
            margin-bottom: 0;
            width: 50%;
        }
    }
}

.textmedia,
.textpic {
    .textpic-text {
        padding: 40px 0;

        @include media-breakpoint-up(xl) {
            padding: 70px 0 100px;
        }
    }

    .textpic-gallery {
        margin-bottom: 0;

        .image {
            margin-right: calc(-50vw + 50%);
            margin-left: calc(-50vw + 50%);
            margin-bottom: 0;

            .figure-img {
                width: 100%;
            }
        }

        .figure-caption {
            display: none;
        }
    }

    @include media-breakpoint-up (md) {
        justify-content: space-between;

        &.textmedia-right,
        &.textpic-right {
            .textmedia-text,
            .textpic-text {
                padding-right: 20px;
            }

            .image {
                margin-left: 0;
                margin-right: calc(-50vw + 100%);
            }
        }

        &.textmedia-left,
        &.textpic-left {
            .textmedia-text,
            .textpic-text {
                padding-left: 20px;
            }

            .image {
                margin-right: 0;
                margin-left: calc(-50vw + 100%);
            }
        }

        .textmedia-text,
        .textpic-text {
            max-width: 520px;
        }

        .textpic-gallery {
            display: flex;

            .gallery-row {
                flex-grow: 1;

                .gallery-item {
                    display: flex;
                    flex-grow: 1;

                    .image {
                        flex-grow: 1;
                        display: flex;
                        margin-bottom: 0;


                        picture {
                            position: relative;
                            flex-grow: 1;

                            .figure-img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .figure-caption {
                display: none;
            }

        }
    }

    @include media-breakpoint-up(xl) {
        &.textmedia-right,
        &.textpic-right {
            .textmedia-text,
            .textpic-text {
                padding-right: 0;
            }
        }

        &.textmedia-left,
        &.textpic-left {
            .textmedia-text,
            .textpic-text {
                padding-left: 0;
            }
        }
    }
}


