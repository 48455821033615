// Cards / Accordion
@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/card";

$card-spacer-y: 10px;
$card-spacer-x: 10px;

//
// card
//
.card-img-top {
    overflow: hidden;
    picture,
    img {
        width: 100%;
        height: auto;
    }
}
.card-body {
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}

//
// Accordion
//
.accordion-item {
    &.card {
        border: none;
    }
}

.accordion-item {
    margin-top: 0.5rem;
}

.accordion-header {
    padding: 0;
    background-color: $accordeon-bg;
    border: none;

    .btn-link {
        position: relative;
        width: 100%;
        padding: $card-spacer-y calc(#{$card-spacer-x} + 2em) $card-spacer-y $card-spacer-x;
        text-align: left;
        border: none;
        box-shadow: none;
        white-space: normal;
        font-size: 0.9rem;
        text-transform: none;

        @include media-breakpoint-up (lg) {
            font-size: 1.0625rem;
        }


        &:hover,
        &:focus {
            text-decoration: underline;
            color: $dark;
        }
    }

    .accordion-header-icon {
        position: absolute;
        height: 10px;
        width: 10px;
        top: 50%;
        right: $card-spacer-x;
        transform: translate(0, -50%);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            height: 1px;
            width: 100%;
            background-color: currentColor;
            transition: all 0.2s ease-in-out;
        }

        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
            left: -7px;
        }
    }

    .btn-link.collapsed {
        .accordion-header-icon {
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

.accordion-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -($card-spacer-x / 2);
    margin-right: -($card-spacer-x / 2);
}

.accordion-content-item {
    flex-grow: 1;
    order: 1;
    padding-left: ($card-spacer-x / 2);
    padding-right: ($card-spacer-x / 2);
    width: 100%;
}

.accordion-content-text {
    *:last-child {
        margin-bottom: 0;
    }
}

.accordion-body {
    border-top: none;
    background-color: $accordeon-bg;
    padding: $card-spacer-y $card-spacer-x;
    color: $black;
    font-size: 0.9rem;

    @include media-breakpoint-up (lg) {
        font-size: 1rem;
    }
}

// - Position
.accordion-content-right,
.accordion-content-left {
    .accordion-content-item {
        @include media-breakpoint-up(md) {
            width: 50%;
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.accordion-content-top,
.accordion-content-left {
    .accordion-content-gallery {
        margin-bottom: $card-spacer-x;
    }
}

.accordion-content-left {
    .accordion-content-gallery {
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}

.accordion-content-bottom,
.accordion-content-right {
    .accordion-content-gallery {
        margin-top: $card-spacer-x;
    }
}

.accordion-content-right {
    .accordion-content-media {
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

//
// Card Group Element
//
.card-group-element {
    margin: -$card-deck-margin;
    margin-bottom: -$card-deck-margin !important;
    display: flex;
    flex-wrap: wrap;
}
.card-group-element-item {
    width: 100%;
    padding: $card-deck-margin;

    .card {
        height: 100%;
        border: 0;
    }

    .card-title {
        font-size: $card-title-size;
        font-weight: $card-title-font-weight;
        font-family: $card-title-font-family;
        margin-bottom: 20px;
        color: $card-title-font-color;
        text-align: center;
    }

    .card-subtitle {
        font-size: $card-subtitle-size;
        font-weight: $font-weight-bold;
        margin-bottom: $card-spacer-y;
    }

    .card-title + .card-subtitle {
        margin-top: -$card-spacer-y;
    }

    .card-footer {
        border-top: 0;
        padding: $card-spacer-x;
        background-color: transparent;
    }
}

.card-group-element-columns-2 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }
    }
}

.card-group-element-columns-3 {
    .card-group-element-item {
        @include media-breakpoint-up('md') {
            width: calc(100% / 3);
        }
    }
}

.card-group-element-columns-4 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }
        @include media-breakpoint-up('xl') {
            width: calc(100% / 4);
        }
    }
}
