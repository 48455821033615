@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


//Bootstrap Optional
@import "BootstrapCustom/images";
@import "BootstrapCustom/transitions";
@import "BootstrapCustom/list-group";
@import "BootstrapCustom/modal";

// Custom Styles
@import "animations";
@import "textpic";
@import "texticon";
@import "gallery";
@import "magnific";
@import "uploads";

@import "cards";
@import "navbar";
@import "slider";
@import "forms";

@each $color, $value in $theme-colors {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value;
    }
}
// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}

iframe.w4-webcam {
    border: none;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 515px;

    @include media-breakpoint-up (md) {
        width: 660px;
    }
}

h1 {
    p {
        margin-bottom: 0;
    }
}


// Footer
.footer {
    &-content {
        background-color: $secondary;
        color: $white;
        font-size: 1.313rem;
        padding: 30px 0 15px;

        @include media-breakpoint-up (md) {
            padding: 30px 0;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
            text-transform: none;
            padding: 40px 0;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.5rem;
        }

        .footer-col{
            width: 100%;
            flex-shrink: 0;
            flex-basis: unset;
        }

        .footer-address {
            line-height: 1.875rem;
            margin-bottom: 25px;
            text-align: center;

            @include media-breakpoint-up (md) {
                margin-bottom: 30px;
            }

            @include media-breakpoint-up (lg) {
                line-height: 2.063rem;
                text-align: left;
                margin-bottom: 75px;
            }

            .contact {
                text-transform: none;

                a {
                    color: $white;

                    &:hover {

                        color: $primary;
                    }
                }
            }
        }

        .badge-footer {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            flex-shrink: 0;

            @include media-breakpoint-up(lg) {
                margin-bottom: 20px;
            }

            .badge-img {
                width: 116px;
                height: 116px;
            }

            img {
                width: 100%;
            }
        }
    }

    &-sociallinks {
        font-size: 2rem;

        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: flex-start;
            align-self: flex-start;
        }

        .list-inline {
            display: flex;
            justify-content: center;
            margin-bottom: 45px;

            @include media-breakpoint-up (lg) {
                justify-content: flex-start;
            }

            .list-inline-item {
                margin-bottom: 0;

                a {
                    color: $white;
                    display: flex;

                    .svg-icon {
                        width: 20px;
                        transform: scale(1);
                        transition: transform $transition-time $transition-ease;
                        fill: $white;
                        color: $white;
                    }

                    &:hover {
                        .svg-icon {
                            transform: scale(1.1);
                            transition: transform $transition-time $transition-ease;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        color: $dark;
                    }


                }
            }
        }
    }

    &-navigation {
        font-size: 0.938rem;

        @include media-breakpoint-up(lg) {
            font-size: 1.063rem;
        }

        ul {
            text-align: center;
        }

        li.list-inline-item {
            margin-right: 0;

            a {
                color: $white;
                text-decoration: none;

                &:hover {
                    color: $primary;
                }
            }
        }


        // Styling für den Link "Themen" im Footer
        .urmenu {
            z-index:1000 !important;
            position: relative;
            display: inline-block;
        }

        .urmenu ul {
            list-style-type :none;
            padding: 0;
            margin: 0;
        }

        .urmenu ul li a {
            color: #658be6 !important;
            background: none repeat scroll 0 0 transparent;
            text-decoration: none;
        }

        .urmenu ul li a:hover {
            color: #9b1825;
        }

        .urmenu > ul :hover > ul {
            visibility: visible;
        }

        .urmenu ul ul {
            visibility:hidden;
            background-color: #fff;
            overflow: visible;
            position: absolute;
            z-index: 1001 !important;
            /*top: -214px;*/ /*google chrome -210px */
            bottom: 20px;
            left: -60px;
            width: 170px;
            border-radius: 3px;
            outline: medium none;
            text-align: left;
            text-indent: 10px;
            font-size: 0.9em;
            line-height: 140%;
        }

        .urmenu ul li ul li a {
            text-decoration: none;
            display: block;
            color: #6d6762;
        }

        .urmenu ul li ul li a:hover {
            background: none repeat scroll 0 0 #fff;
            color: #f5f5ec;
            outline: medium none;
            text-decoration: underline;

        }

        .urmenu ul ul ul {
            visibility:hidden;
            background: #fff;
            overflow: visible;
            position: absolute;
            z-index: 1002 !important;
            top: 0px;
            bottom: 0px;
            left: -210px;
            width: 210px;
            border-radius: 3px;
            outline: medium none;
            text-align: left;
            text-indent: 10px;
            font-size: 1em;
            line-height: 140%;
        }

        .urmenu ul :hover ul :hover ul{
            visibility:visible;
        }
    }
}



//
// Scrolltop
//
.scroll-top {
    opacity: 0;
    z-index: 1050;
    background-color: $primary;
    color: $white;
    bottom: -1px;
    right: -1px;
    position: fixed;
    display: none;
    transform: translateX(100vw);
    transition: all ($transition-time * 3) $transition-ease;

    @media (min-width: 1600px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

    }

    .scroll-top-link {
        overflow: hidden;
        width: 3em;
        height: 3em;
        display: block;
        position: relative;
        color: $dark;
        transform: scale(1);
        transition: transform $transition-time $transition-ease;

        &:active,
        &:focus,
        &:hover {
            transform: scale(1.1);
            transition: transform $transition-time $transition-ease;
        }
        .scroll-top-icon {
            @extend .css-arrow;
        }
    }

    &.scroll-top-visible {
        transform: translateX(0);
        opacity: 1;
        transition: all ($transition-time * 3) $transition-ease;
    }
}

.css-arrow {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &:before {
        content: '';
        position: absolute;
        height: 30%;
        width: 30%;
        border-top: 1px solid $white;
        border-left: 1px solid $white;
        top: 55%;
        left: 55%;
        transform: translate(-50%, -50%);
    }
}

.embed-responsive-16by9 {
    position: relative;
    padding-top: 56.25%; //16by9

    .embed-responsive-item {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
