/* This Setting is for Autoprefixer IE Support CSS Grid
// https://github.com/postcss/autoprefixer#options*/
/* autoprefixer grid: autoplace */
.img-fluid {
  max-width: 100%;
  height: auto;
}

/*
.img-thumbnail {
  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);

  // Keep them at most 100% wide
  @include img-fluid();
}
*/
.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #ffffff;
}

.list-group-item-action:active {
  color: #4D4F4D;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(51, 51, 51, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #01257D;
  border-color: #01257D;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #192c59;
  background-color: #b8c2db;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #192c59;
  background-color: #a7b3d2;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #192c59;
  border-color: #192c59;
}

.list-group-item-secondary {
  color: #4d6190;
  background-color: #d4dff8;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #4d6190;
  background-color: #becff4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #4d6190;
  border-color: #4d6190;
}

.list-group-item-success {
  color: #2d6f3c;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2d6f3c;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2d6f3c;
  border-color: #2d6f3c;
}

.list-group-item-info {
  color: #246d78;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #246d78;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #246d78;
  border-color: #246d78;
}

.list-group-item-warning {
  color: #9d7d1c;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #9d7d1c;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #9d7d1c;
  border-color: #9d7d1c;
}

.list-group-item-danger {
  color: #8b343c;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #8b343c;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8b343c;
  border-color: #8b343c;
}

.list-group-item-light {
  color: #989898;
  background-color: #fcfcfc;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #989898;
  background-color: #efefef;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #989898;
  border-color: #989898;
}

.list-group-item-dark {
  color: #414241;
  background-color: #cdcecd;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #414241;
  background-color: #c0c1c0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #414241;
  border-color: #414241;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #333333;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #949490;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.467;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #949490;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(100vw);
  }
  45% {
    opacity: 0;
  }
  80% {
    opacity: 0.2;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showLinks {
  0% {
    width: 51px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 250px;
  }
}

@keyframes showText {
  0% {
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 0;
    height: 0;
    width: 100%;
  }
  100% {
    opacity: 1;
    height: 30px;
    width: 100%;
  }
}

@keyframes moreMargin {
  0% {
    margin: 0;
  }
  50% {
    margin: 0;
  }
  100% {
    margin: 15px 0 0 0;
  }
}

@keyframes hideLinks {
  0% {
    width: 250px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 51px;
  }
}

@keyframes hideText {
  0% {
    opacity: 1;
    height: 30px;
    width: 100%;
  }
  50% {
    opacity: 0;
    height: 0;
    width: 100%;
  }
  100% {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

@keyframes lessMargin {
  0% {
    margin: 15px 0 0 0;
  }
  100% {
    margin: 0;
  }
}

.textmedia,
.textpic {
  display: flex;
  flex-wrap: wrap;
}

.textmedia .textpic-gallery,
.textpic .textpic-gallery {
  order: 1;
}

.textmedia .textpic-text,
.textpic .textpic-text {
  order: 2;
}

@media (min-width: 768px) {
  .textmedia-right .textpic-gallery,
  .textpic-right .textpic-gallery {
    order: 2;
  }
  .textmedia-right .textpic-text,
  .textpic-right .textpic-text {
    order: 1;
  }
}

.textmedia-item,
.textpic-item {
  order: 1;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.textmedia-text > *:last-child,
.textpic-text > *:last-child {
  margin-bottom: 0;
}

.textmedia-gallery,
.textpic-gallery {
  margin-bottom: 10px;
}

.textmedia-left .textmedia-gallery,
.textmedia-left .textpic-gallery,
.textpic-left .textmedia-gallery,
.textpic-left .textpic-gallery {
  order: 2;
}

@media (min-width: 768px) {
  .textmedia-left .textmedia-gallery,
  .textmedia-left .textpic-gallery,
  .textpic-left .textmedia-gallery,
  .textpic-left .textpic-gallery {
    order: 1;
  }
}

.textmedia-below .textmedia-text,
.textmedia-below .textpic-text,
.textpic-below .textmedia-text,
.textpic-below .textpic-text {
  margin-bottom: 10px;
}

.textmedia-below .textmedia-gallery,
.textmedia-below .textpic-gallery,
.textpic-below .textmedia-gallery,
.textpic-below .textpic-gallery {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .textmedia-text,
  .textpic-text {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .textmedia-right .textmedia-item,
  .textmedia-right .textpic-item,
  .textmedia-left .textmedia-item,
  .textmedia-left .textpic-item,
  .textpic-right .textmedia-item,
  .textpic-right .textpic-item,
  .textpic-left .textmedia-item,
  .textpic-left .textpic-item {
    margin-bottom: 0;
    width: 50%;
  }
}

.textmedia .textpic-text,
.textpic .textpic-text {
  padding: 40px 0;
}

@media (min-width: 1200px) {
  .textmedia .textpic-text,
  .textpic .textpic-text {
    padding: 70px 0 100px;
  }
}

.textmedia .textpic-gallery,
.textpic .textpic-gallery {
  margin-bottom: 0;
}

.textmedia .textpic-gallery .image,
.textpic .textpic-gallery .image {
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
  margin-bottom: 0;
}

.textmedia .textpic-gallery .image .figure-img,
.textpic .textpic-gallery .image .figure-img {
  width: 100%;
}

.textmedia .textpic-gallery .figure-caption,
.textpic .textpic-gallery .figure-caption {
  display: none;
}

@media (min-width: 768px) {
  .textmedia,
  .textpic {
    justify-content: space-between;
  }
  .textmedia.textmedia-right .textmedia-text,
  .textmedia.textmedia-right .textpic-text, .textmedia.textpic-right .textmedia-text,
  .textmedia.textpic-right .textpic-text,
  .textpic.textmedia-right .textmedia-text,
  .textpic.textmedia-right .textpic-text,
  .textpic.textpic-right .textmedia-text,
  .textpic.textpic-right .textpic-text {
    padding-right: 20px;
  }
  .textmedia.textmedia-right .image, .textmedia.textpic-right .image,
  .textpic.textmedia-right .image,
  .textpic.textpic-right .image {
    margin-left: 0;
    margin-right: calc(-50vw + 100%);
  }
  .textmedia.textmedia-left .textmedia-text,
  .textmedia.textmedia-left .textpic-text, .textmedia.textpic-left .textmedia-text,
  .textmedia.textpic-left .textpic-text,
  .textpic.textmedia-left .textmedia-text,
  .textpic.textmedia-left .textpic-text,
  .textpic.textpic-left .textmedia-text,
  .textpic.textpic-left .textpic-text {
    padding-left: 20px;
  }
  .textmedia.textmedia-left .image, .textmedia.textpic-left .image,
  .textpic.textmedia-left .image,
  .textpic.textpic-left .image {
    margin-right: 0;
    margin-left: calc(-50vw + 100%);
  }
  .textmedia .textmedia-text,
  .textmedia .textpic-text,
  .textpic .textmedia-text,
  .textpic .textpic-text {
    max-width: 520px;
  }
  .textmedia .textpic-gallery,
  .textpic .textpic-gallery {
    display: flex;
  }
  .textmedia .textpic-gallery .gallery-row,
  .textpic .textpic-gallery .gallery-row {
    flex-grow: 1;
  }
  .textmedia .textpic-gallery .gallery-row .gallery-item,
  .textpic .textpic-gallery .gallery-row .gallery-item {
    display: flex;
    flex-grow: 1;
  }
  .textmedia .textpic-gallery .gallery-row .gallery-item .image,
  .textpic .textpic-gallery .gallery-row .gallery-item .image {
    flex-grow: 1;
    display: flex;
    margin-bottom: 0;
  }
  .textmedia .textpic-gallery .gallery-row .gallery-item .image picture,
  .textpic .textpic-gallery .gallery-row .gallery-item .image picture {
    position: relative;
    flex-grow: 1;
  }
  .textmedia .textpic-gallery .gallery-row .gallery-item .image picture .figure-img,
  .textpic .textpic-gallery .gallery-row .gallery-item .image picture .figure-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .textmedia .textpic-gallery .figure-caption,
  .textpic .textpic-gallery .figure-caption {
    display: none;
  }
}

@media (min-width: 1200px) {
  .textmedia.textmedia-right .textmedia-text,
  .textmedia.textmedia-right .textpic-text, .textmedia.textpic-right .textmedia-text,
  .textmedia.textpic-right .textpic-text,
  .textpic.textmedia-right .textmedia-text,
  .textpic.textmedia-right .textpic-text,
  .textpic.textpic-right .textmedia-text,
  .textpic.textpic-right .textpic-text {
    padding-right: 0;
  }
  .textmedia.textmedia-left .textmedia-text,
  .textmedia.textmedia-left .textpic-text, .textmedia.textpic-left .textmedia-text,
  .textmedia.textpic-left .textpic-text,
  .textpic.textmedia-left .textmedia-text,
  .textpic.textmedia-left .textpic-text,
  .textpic.textpic-left .textmedia-text,
  .textpic.textpic-left .textpic-text {
    padding-left: 0;
  }
}

.texticon::after {
  display: block;
  clear: both;
  content: "";
}

.texticon-above .texticon-icon {
  text-align: center;
  margin-bottom: 1rem;
}

.texticon-left .texticon-icon, .texticon-right .texticon-icon {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.texticon-left .texticon-content, .texticon-right .texticon-content {
  overflow: hidden;
  zoom: 1;
}

.texticon-left .texticon-content *:first-child, .texticon-right .texticon-content *:first-child {
  margin-top: 0;
}

.texticon-left .texticon-content *:last-child, .texticon-right .texticon-content *:last-child {
  margin-bottom: 0;
}

.texticon-left .texticon-icon {
  float: left;
  margin-left: -10px;
}

.texticon-right .texticon-icon {
  float: right;
  margin-right: -10px;
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.gallery-item {
  padding-left: 10px;
  padding-right: 10px;
}

figure.image {
  margin-bottom: 20px;
}

figure.image img,
figure.image picture {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

figure.image a picture:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

figure.image picture {
  overflow: hidden;
}

figure.image img {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.gallery-item-size-1 {
  width: 100%;
}

.gallery-item-size-2 {
  width: calc(100% / 2);
}

.gallery-item-size-3 {
  width: calc(100% / 3);
}

.gallery-item-size-4 {
  width: calc(100% / 2);
}

@media (min-width: 768px) {
  .gallery-item-size-4 {
    width: calc(100% / 4);
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* This Setting is for Autoprefixer IE Support CSS Grid
// https://github.com/postcss/autoprefixer#options*/
/* autoprefixer grid: autoplace */
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.media-list .media + .media {
  margin-top: 0.5rem;
}

.media-left {
  padding-right: 0.5rem;
}

.media-heading {
  display: flex;
  align-items: center;
}

.media-heading > a {
  display: inline-flex;
  align-items: center;
}

.uploads-fileicon {
  margin-right: 0.25rem;
}

.uploads-fileicon svg {
  width: 1em;
  height: 1em;
}

.uploads-filesize {
  margin-left: 0.25rem;
}

/* This Setting is for Autoprefixer IE Support CSS Grid
// https://github.com/postcss/autoprefixer#options*/
/* autoprefixer grid: autoplace */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(51, 51, 51, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(51, 51, 51, 0.03);
  border-bottom: 1px solid rgba(51, 51, 51, 0.125);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(51, 51, 51, 0.03);
  border-top: 1px solid rgba(51, 51, 51, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card-img-top {
  overflow: hidden;
}

.card-img-top picture,
.card-img-top img {
  width: 100%;
  height: auto;
}

.card-body > *:first-child {
  margin-top: 0;
}

.card-body > *:last-child {
  margin-bottom: 0;
}

.accordion-item.card {
  border: none;
}

.accordion-item {
  margin-top: 0.5rem;
}

.accordion-header {
  padding: 0;
  background-color: #F6F6F6;
  border: none;
}

.accordion-header .btn-link {
  position: relative;
  width: 100%;
  padding: 10px calc(10px + 2em) 10px 10px;
  text-align: left;
  border: none;
  box-shadow: none;
  white-space: normal;
  font-size: 0.9rem;
  text-transform: none;
}

@media (min-width: 992px) {
  .accordion-header .btn-link {
    font-size: 1.0625rem;
  }
}

.accordion-header .btn-link:hover, .accordion-header .btn-link:focus {
  text-decoration: underline;
  color: #4D4F4D;
}

.accordion-header .accordion-header-icon {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}

.accordion-header .accordion-header-icon:before, .accordion-header .accordion-header-icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  transition: all 0.2s ease-in-out;
}

.accordion-header .accordion-header-icon:before {
  transform: rotate(-45deg);
}

.accordion-header .accordion-header-icon:after {
  transform: rotate(45deg);
  left: -7px;
}

.accordion-header .btn-link.collapsed .accordion-header-icon:before {
  transform: rotate(45deg);
}

.accordion-header .btn-link.collapsed .accordion-header-icon:after {
  transform: rotate(-45deg);
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.accordion-content-item {
  flex-grow: 1;
  order: 1;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.accordion-content-text *:last-child {
  margin-bottom: 0;
}

.accordion-body {
  border-top: none;
  background-color: #F6F6F6;
  padding: 10px 10px;
  color: #333333;
  font-size: 0.9rem;
}

@media (min-width: 992px) {
  .accordion-body {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-item,
  .accordion-content-left .accordion-content-item {
    width: 50%;
  }
  .accordion-content-right .accordion-content-item *:last-child,
  .accordion-content-left .accordion-content-item *:last-child {
    margin-bottom: 0;
  }
}

.accordion-content-top .accordion-content-gallery,
.accordion-content-left .accordion-content-gallery {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .accordion-content-left .accordion-content-gallery {
    margin-bottom: 0;
  }
}

.accordion-content-bottom .accordion-content-gallery,
.accordion-content-right .accordion-content-gallery {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-media {
    margin-top: 0;
  }
}

.card-group-element {
  margin: -10px;
  margin-bottom: -10px !important;
  display: flex;
  flex-wrap: wrap;
}

.card-group-element-item {
  width: 100%;
  padding: 10px;
}

.card-group-element-item .card {
  height: 100%;
  border: 0;
}

.card-group-element-item .card-title {
  font-size: 2.125rem;
  font-weight: 400;
  font-family: "DM Serif Display", sans-serif;
  margin-bottom: 20px;
  color: #01257D;
  text-align: center;
}

.card-group-element-item .card-subtitle {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.card-group-element-item .card-title + .card-subtitle {
  margin-top: -10px;
}

.card-group-element-item .card-footer {
  border-top: 0;
  padding: 10px;
  background-color: transparent;
}

@media (min-width: 576px) {
  .card-group-element-columns-2 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(100% / 3);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 1200px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 4);
  }
}

/* This Setting is for Autoprefixer IE Support CSS Grid
// https://github.com/postcss/autoprefixer#options*/
/* autoprefixer grid: autoplace */
html.main-navbar-open {
  overflow: hidden;
}

.menu-open .navbar .navbar-wrapper {
  visibility: visible;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  overflow-y: scroll;
}

.navbar .navbar-wrapper {
  background-color: #fff;
  visibility: hidden;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1030;
}

@media (min-width: 992px) {
  .navbar .navbar-wrapper {
    visibility: visible;
    top: 0;
    transform: translateY(0);
    height: auto;
    padding: 0 20px;
  }
}

.navbar .navbar-collapse {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  background-color: #fff;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media (min-width: 992px) {
  .navbar .navbar-collapse {
    padding: 0;
  }
}

.navbar .navbar-collapse .w4-navigation-second-part {
  margin-bottom: 100px;
}

@media (min-width: 992px) {
  .navbar .navbar-collapse .w4-navigation-second-part {
    margin-bottom: 0;
  }
}

.navbar .navbar-nav .nav-link {
  font-family: "Red Hat Display", sans-serif;
  font-size: 1.125rem;
  line-height: 1;
  padding: 12px 0;
  text-align: center;
  background: #F6F6F6;
}

.navbar .navbar-nav .nav-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15px;
}

@media (min-width: 1200px) {
  .navbar .navbar-nav .nav-item {
    padding-bottom: 20px;
  }
}

.navbar .navbar-nav .dropdown {
  position: static;
}

.navbar .navbar-nav .dropdown .dropdown-menu {
  padding: 0;
  display: block;
}

.navbar .navbar-nav .dropdown .dropdown-menu ul {
  list-style-type: none;
  padding: 5px 0 15px 0;
}

.navbar .navbar-nav .dropdown .dropdown-menu ul .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown .dropdown-menu ul .nav-link {
    content: initial;
  }
}

.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item:after {
  content: '';
  display: block;
  margin: 0 auto;
  width: 66px;
  border-bottom: 1px solid #A5A5AA;
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item:after {
    content: initial;
  }
}

.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item:last-child:after {
  border-bottom: 0;
}

.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item .nav-link {
  font-size: 0.875rem;
  background: transparent;
  padding: 9px 0;
}

.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item .nav-link.active {
  color: #fff;
  background: #01257D;
}

@media (min-width: 992px) {
  .navbar.navbar-expand-lg .navbar-collapse {
    background: #fff;
  }
  .navbar.navbar-expand-lg .navbar-nav {
    align-items: flex-end;
  }
  .navbar.navbar-expand-lg .navbar-nav .nav-item.active .nav-link::after {
    right: 0;
    transition: right 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .nav-item.active .dropdown-menu-item .nav-link::after {
    right: 100%;
  }
  .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link {
    line-height: 1.2;
    font-size: 1.125rem;
    position: relative;
    background-color: #fff;
  }
  .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link::after {
    content: '';
    position: absolute;
    bottom: 10px;
    height: 2px;
    left: 0;
    right: 100%;
    background-color: #4D4F4D;
    transition: right 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link:hover, .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link:focus, .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link:active {
    color: #333333;
  }
  .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link:hover::after, .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link:focus::after, .navbar.navbar-expand-lg .navbar-nav .nav-item .nav-link:active::after {
    right: 0;
    transition: right 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu {
    padding: 0 30px;
    background: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Icons/stripe-pattern.svg");
    border: 0;
    display: none;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show {
    left: auto;
    display: block;
    position: fixed;
    min-width: inherit;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show ul {
    padding: 16px 0;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.nav-link {
    width: 100%;
    height: 100%;
    padding: 9px 0;
    position: relative;
    color: #fff;
    font-size: 1.063rem;
    line-height: 1;
    justify-content: flex-start;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.nav-link::after {
    display: none;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:hover, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:focus, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:active, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.active {
    background: transparent;
    text-decoration: underline;
  }
  .navbar .nav-item .fa-chevron-down {
    position: relative;
    top: -1px;
    margin-left: 0.5rem;
  }
}

@keyframes dropdown-fade {
  from {
    opacity: 0;
    transform: translate(-50%, -1rem);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/*IE 11 Browserhack to fix Submenu background issue*/
@media all and (-ms-high-contrast: none) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    min-width: 600px;
  }
}

/* Splide Core Files */
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.splide {
  position: relative;
  visibility: hidden;
  outline: none;
}

.splide__container {
  position: relative;
  box-sizing: border-box;
}

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: max-content;
  will-change: transform;
}

.splide.is-active .splide__list {
  display: flex;
}

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0;
}

.splide__slide {
  position: relative;
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  flex-shrink: 0;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  user-select: none;
}

.splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

/* Splide Default Style Files */
.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent;
}

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: black;
}

.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.element-header {
  margin-bottom: 20px;
}

.image-splide .splide__track {
  z-index: 1;
}

.image-splide .splide__track .image {
  overflow: hidden;
}

.image-splide .splide__track .image img {
  width: 100%;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.image-splide .splide__track .image .figure-caption {
  display: none;
}

.image-splide .splide__track .image:hover img, .image-splide .splide__track .image:focus img {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.css-arrow-slider, .splide__arrow {
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.css-arrow-slider:before, .splide__arrow:before {
  content: '';
  position: absolute;
  height: 80%;
  width: 80%;
  border-top: 1px solid #333333;
  border-left: 1px solid #333333;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.splide.image-splide {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .splide.image-splide {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.splide__arrows {
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-17px);
}

.splide__arrow {
  display: block;
  position: absolute;
  left: auto;
  right: auto;
  width: 25px;
  height: 25px;
  border: 0;
  padding: 0;
  background: transparent;
  transition: fill 0.3s ease-in-out;
}

@media (min-width: 992px) {
  .splide__arrow {
    width: 35px;
    height: 35px;
  }
}

.splide__arrow--prev {
  transform: rotate(-45deg);
  left: -15px;
}

@media (min-width: 992px) {
  .splide__arrow--prev {
    left: 0;
  }
}

.splide__arrow--next {
  transform: rotate(135deg);
  right: -15px;
}

@media (min-width: 992px) {
  .splide__arrow--next {
    right: 0;
  }
}

.splide__arrow:hover {
  cursor: pointer;
}

.splide__arrow:hover:not(:disabled) {
  fill: #658BE6;
}

.splide__arrow:focus {
  outline: none;
}

.splide__arrow:focus:not(:disabled) {
  fill: #658BE6;
}

/* This Setting is for Autoprefixer IE Support CSS Grid
// https://github.com/postcss/autoprefixer#options*/
/* autoprefixer grid: autoplace */
.form-control {
  display: block;
  width: 100%;
  height: 3.75658rem;
  padding: 1.375rem 0.75rem 1.25rem;
  font-family: "Red Hat Display", sans-serif;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #4D4F4D;
  background-color: rgba(255, 255, 255, 0.5);
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out;
}

.frame-background-light .form-control {
  background-color: rgba(77, 79, 77, 0.08);
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

@media (min-width: 992px) {
  .form-control {
    padding: 1.5rem 1rem 1.375rem;
    height: 4.00658rem;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4D4F4D;
}

.form-control:focus {
  color: #4D4F4D;
  background-color: rgba(148, 148, 144, 0.5);
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #4D4F4D;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #949490;
  opacity: 1;
}

.form-control:disabled {
  cursor: not-allowed;
}

.form-control[type="file"] {
  padding-top: 1.25rem;
}

@media (min-width: 992px) {
  .form-control[type="file"] {
    padding-top: 1.375rem;
  }
}

textarea.form-control {
  height: auto;
  min-height: 9rem;
  resize: vertical;
}

.form-group {
  margin-bottom: 1rem;
}

.form-set {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}

/*.valid-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }

    @include form-validation-state-selector($state) {
        ~ .valid-feedback,
        ~ .valid-tooltip {
            display: block;
        }
    }*/
.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%2301257D' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}

@media (min-width: 992px) {
  .was-validated .form-control:valid, .form-control.is-valid {
    padding-right: 2rem;
    background-position: right 1rem center;
  }
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 1.5rem;
  background-position: top 1.375rem right 0.75rem;
}

@media (min-width: 992px) {
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: 2rem;
    background-position: top 1.5rem right 1rem;
  }
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234D4F4D' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%2301257D' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'%3e%3c/path%3e%3c/svg%3e") rgba(255, 255, 255, 0.5) no-repeat center right 1.75rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}

.was-validated .custom-control-input:valid, .custom-control-input.is-valid {
  /*            &:checked {
                            ~ .custom-control-label::before {
                                border-color: lighten($color, 10%);
                                @include gradient-bg(lighten($color, 10%));
                            }
                        }

                        &:focus {
                            ~ .custom-control-label::before {
                                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                            }

                            &:not(:checked) ~ .custom-control-label::before {
                                border-color: $color;
                            }
                        }*/
}

/*.invalid-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }

    @include form-validation-state-selector($state) {
        ~ .invalid-feedback,
        ~ .invalid-tooltip {
            display: block;
        }
    }*/
.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='red' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}

@media (min-width: 992px) {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    padding-right: 2rem;
    background-position: right 1rem center;
  }
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 1.5rem;
  background-position: top 1.375rem right 0.75rem;
}

@media (min-width: 992px) {
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: 2rem;
    background-position: top 1.5rem right 1rem;
  }
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234D4F4D' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='red' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'%3e%3c/path%3e%3c/svg%3e") rgba(255, 255, 255, 0.5) no-repeat center right 1.75rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}

.was-validated .custom-control-input:invalid, .custom-control-input.is-invalid {
  /*            &:checked {
                            ~ .custom-control-label::before {
                                border-color: lighten($color, 10%);
                                @include gradient-bg(lighten($color, 10%));
                            }
                        }

                        &:focus {
                            ~ .custom-control-label::before {
                                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                            }

                            &:not(:checked) ~ .custom-control-label::before {
                                border-color: $color;
                            }
                        }*/
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: red;
}

label {
  font-size: 0.938rem;
  line-height: 1.13158;
}

.form-group-floating {
  position: relative;
}

.form-group-floating label {
  position: absolute;
  left: 0.75rem;
  top: 1.375rem;
  color: #4D4F4D;
  transition: all 0.15s ease-in-out;
}

@media (min-width: 992px) {
  .form-group-floating label {
    left: 1rem;
    top: 1.5rem;
  }
}

.form-group-floating input:focus ~ label,
.form-group-floating textarea:focus ~ label,
.form-group-floating.input-filled > label {
  font-size: 0.625rem;
  top: 0.25rem;
  color: #4D4F4D;
}

@media (min-width: 992px) {
  .form-group-floating input:focus ~ label,
  .form-group-floating textarea:focus ~ label,
  .form-group-floating.input-filled > label {
    top: 0.5rem;
  }
}

.custom-control {
  position: relative;
  z-index: 1;
}

.custom-control .custom-control-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index: -1;
}

.custom-control .custom-control-label {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 2.5rem;
}

.custom-control .custom-control-label::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  margin-top: -0.75rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: rgba(77, 79, 77, 0.08);
}

.frame-background-dark .custom-control .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-control .custom-control-label::after {
  position: absolute;
  display: none;
  content: "";
  top: 50%;
  margin-top: -6px;
}

.custom-control:hover .custom-control-label::before {
  background-color: rgba(77, 79, 77, 0.12);
}

.frame-background-dark .custom-control:hover .custom-control-label::before {
  background-color: rgba(148, 148, 144, 0.5);
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: rgba(77, 79, 77, 0.12);
}

.frame-background-dark .custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: rgba(148, 148, 144, 0.5);
}

.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01257D;
}

.frame-background-dark .custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01257D;
}

.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  display: block;
}

.custom-control.custom-control-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control.custom-control-checkbox .custom-control-label::after {
  width: 6px;
  height: 12px;
  left: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-control.custom-control-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-control.custom-control-radio .custom-control-label::after {
  width: 12px;
  height: 12px;
  left: 6px;
  background-color: #fff;
  border-radius: 50%;
}

label.form-group-label {
  font-weight: 500;
  font-size: 0.938rem;
  margin-bottom: 1rem;
}

.input.checkbox .inputs-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .input.checkbox .inputs-list {
    flex-direction: row;
  }
}

.input.checkbox input {
  margin-right: 5px;
}

.custom-control a {
  text-decoration: underline;
}

.custom-control.font-xs .custom-control-label {
  font-size: 0.938rem;
  line-height: 1.467;
}

.custom-control.custom-control-block {
  width: 100%;
  padding-left: 0;
  margin-bottom: 1rem;
}

.custom-control.custom-control-block .custom-control-label {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.75658rem;
  height: 3.75658rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.75rem 0.75rem 0.75rem 3.25rem;
  border-radius: 0;
}

@media (min-width: 992px) {
  .custom-control.custom-control-block .custom-control-label {
    min-height: 4.00658rem;
    height: 4.00658rem;
  }
}

.custom-control.custom-control-block .custom-control-label:hover {
  background-color: rgba(148, 148, 144, 0.5);
}

.custom-control.custom-control-block .custom-control-label::before {
  left: 0.75rem;
}

.custom-control.custom-control-block.custom-control-checkbox .custom-control-label::after {
  left: 1.3125rem;
}

.custom-control.custom-control-block.custom-control-radio .custom-control-label::after {
  left: 1.125rem;
}

.custom-control.custom-control-block .custom-control-input:checked ~ .custom-control-label {
  background-color: #01257D;
  color: #fff;
}

.custom-control.custom-control-block .custom-control-input:focus:not(:checked) ~ .custom-control-label {
  background-color: rgba(148, 148, 144, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.75658rem;
  padding: 1.375rem 0.75rem 1.25rem;
  font-family: "Red Hat Display", sans-serif;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #4D4F4D;
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.5) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234D4F4D' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 0;
  border-radius: 0;
  appearance: none;
}

@media (min-width: 992px) {
  .custom-select {
    padding: 1.5rem 1rem 1.375rem;
    height: 4.00658rem;
  }
}

.custom-select:focus {
  color: #4D4F4D;
  background-color: rgba(148, 148, 144, 0.5);
  outline: 0;
  box-shadow: none;
}

.custom-select:focus::-ms-value {
  color: #4D4F4D;
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4D4F4D;
}

.form-group-select {
  position: relative;
}

.form-group-select label {
  position: absolute;
  left: 0.75rem;
  top: 0.25rem;
  color: #4D4F4D;
  font-size: 0.625rem;
}

@media (min-width: 992px) {
  .form-group-select label {
    left: 1rem;
    top: 0.5rem;
  }
}

.help-block {
  font-size: 80%;
  line-height: 1.467;
  display: inline-block;
  color: red;
}

.form-ce {
  padding-left: 0;
  padding-right: 0;
}

.form-ce p {
  line-height: 1.3;
}

@media (min-width: 992px) {
  #contactform-48-fieldset-contactdata .form-row .col-12:nth-child(4) {
    order: 3;
    transform: translateY(-100%);
  }
  #contactform-48-fieldset-contactdata .form-row .col-12:nth-child(4) span.error {
    position: absolute;
  }
  #contactform-48-fieldset-contactdata .form-row .col-12:nth-child(5) {
    order: 2;
  }
}

.swatch-primary {
  color: #fff;
  background-color: #01257D;
}

.swatch-secondary {
  color: #fff;
  background-color: #658BE6;
}

.swatch-success {
  color: #fff;
  background-color: #28a745;
}

.swatch-danger {
  color: #fff;
  background-color: #dc3545;
}

.swatch-light {
  color: #212529;
  background-color: #F6F6F6;
}

.swatch-dark {
  color: #fff;
  background-color: #4D4F4D;
}

.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 1070;
}

iframe.w4-webcam {
  border: none;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 515px;
}

@media (min-width: 768px) {
  iframe.w4-webcam {
    width: 660px;
  }
}

h1 p {
  margin-bottom: 0;
}

.footer-content {
  background-color: #658BE6;
  color: #fff;
  font-size: 1.313rem;
  padding: 30px 0 15px;
}

@media (min-width: 768px) {
  .footer-content {
    padding: 30px 0;
  }
}

@media (min-width: 992px) {
  .footer-content {
    font-size: 1.25rem;
    text-transform: none;
    padding: 40px 0;
  }
}

@media (min-width: 1200px) {
  .footer-content {
    font-size: 1.5rem;
  }
}

.footer-content .footer-col {
  width: 100%;
  flex-shrink: 0;
  flex-basis: unset;
}

.footer-content .footer-address {
  line-height: 1.875rem;
  margin-bottom: 25px;
  text-align: center;
}

@media (min-width: 768px) {
  .footer-content .footer-address {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .footer-content .footer-address {
    line-height: 2.063rem;
    text-align: left;
    margin-bottom: 75px;
  }
}

.footer-content .footer-address .contact {
  text-transform: none;
}

.footer-content .footer-address .contact a {
  color: #fff;
}

.footer-content .footer-address .contact a:hover {
  color: #01257D;
}

.footer-content .badge-footer {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-shrink: 0;
}

@media (min-width: 992px) {
  .footer-content .badge-footer {
    margin-bottom: 20px;
  }
}

.footer-content .badge-footer .badge-img {
  width: 116px;
  height: 116px;
}

.footer-content .badge-footer img {
  width: 100%;
}

.footer-sociallinks {
  font-size: 2rem;
}

@media (min-width: 1200px) {
  .footer-sociallinks {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
  }
}

.footer-sociallinks .list-inline {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .footer-sociallinks .list-inline {
    justify-content: flex-start;
  }
}

.footer-sociallinks .list-inline .list-inline-item {
  margin-bottom: 0;
}

.footer-sociallinks .list-inline .list-inline-item a {
  color: #fff;
  display: flex;
}

.footer-sociallinks .list-inline .list-inline-item a .svg-icon {
  width: 20px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  fill: #fff;
  color: #fff;
}

.footer-sociallinks .list-inline .list-inline-item a:hover .svg-icon {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 992px) {
  .footer-sociallinks .list-inline .list-inline-item a {
    color: #4D4F4D;
  }
}

.footer-navigation {
  font-size: 0.938rem;
}

@media (min-width: 992px) {
  .footer-navigation {
    font-size: 1.063rem;
  }
}

.footer-navigation ul {
  text-align: center;
}

.footer-navigation li.list-inline-item {
  margin-right: 0;
}

.footer-navigation li.list-inline-item a {
  color: #fff;
  text-decoration: none;
}

.footer-navigation li.list-inline-item a:hover {
  color: #01257D;
}

.footer-navigation .urmenu {
  z-index: 1000 !important;
  position: relative;
  display: inline-block;
}

.footer-navigation .urmenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-navigation .urmenu ul li a {
  color: #658be6 !important;
  background: none repeat scroll 0 0 transparent;
  text-decoration: none;
}

.footer-navigation .urmenu ul li a:hover {
  color: #9b1825;
}

.footer-navigation .urmenu > ul :hover > ul {
  visibility: visible;
}

.footer-navigation .urmenu ul ul {
  visibility: hidden;
  background-color: #fff;
  overflow: visible;
  position: absolute;
  z-index: 1001 !important;
  /*top: -214px;*/
  /*google chrome -210px */
  bottom: 20px;
  left: -60px;
  width: 170px;
  border-radius: 3px;
  outline: medium none;
  text-align: left;
  text-indent: 10px;
  font-size: 0.9em;
  line-height: 140%;
}

.footer-navigation .urmenu ul li ul li a {
  text-decoration: none;
  display: block;
  color: #6d6762;
}

.footer-navigation .urmenu ul li ul li a:hover {
  background: none repeat scroll 0 0 #fff;
  color: #f5f5ec;
  outline: medium none;
  text-decoration: underline;
}

.footer-navigation .urmenu ul ul ul {
  visibility: hidden;
  background: #fff;
  overflow: visible;
  position: absolute;
  z-index: 1002 !important;
  top: 0px;
  bottom: 0px;
  left: -210px;
  width: 210px;
  border-radius: 3px;
  outline: medium none;
  text-align: left;
  text-indent: 10px;
  font-size: 1em;
  line-height: 140%;
}

.footer-navigation .urmenu ul :hover ul :hover ul {
  visibility: visible;
}

.scroll-top {
  opacity: 0;
  z-index: 1050;
  background-color: #01257D;
  color: #fff;
  bottom: -1px;
  right: -1px;
  position: fixed;
  display: none;
  transform: translateX(100vw);
  transition: all 0.9s ease-in-out;
}

@media (min-width: 1600px) {
  .scroll-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.scroll-top .scroll-top-link {
  overflow: hidden;
  width: 3em;
  height: 3em;
  display: block;
  position: relative;
  color: #4D4F4D;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.scroll-top .scroll-top-link:active, .scroll-top .scroll-top-link:focus, .scroll-top .scroll-top-link:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.scroll-top.scroll-top-visible {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.9s ease-in-out;
}

.css-arrow, .scroll-top .scroll-top-link .scroll-top-icon {
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.css-arrow:before, .scroll-top .scroll-top-link .scroll-top-icon:before {
  content: '';
  position: absolute;
  height: 30%;
  width: 30%;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.embed-responsive-16by9 {
  position: relative;
  padding-top: 56.25%;
}

.embed-responsive-16by9 .embed-responsive-item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
