/* Splide Core Files */
@import '@splidejs/splide/src/sass/core/foundation/variables';
@import '@splidejs/splide/src/sass/core/foundation/animations';

@import '@splidejs/splide/src/sass/core/object/objects/root';
@import '@splidejs/splide/src/sass/core/object/objects/container';
@import '@splidejs/splide/src/sass/core/object/objects/list';
@import '@splidejs/splide/src/sass/core/object/objects/pagination';
@import '@splidejs/splide/src/sass/core/object/objects/slide';
@import '@splidejs/splide/src/sass/core/object/objects/slider';
//@import '@splidejs/splide/src/sass/core/object/objects/spinner';
@import '@splidejs/splide/src/sass/core/object/objects/track';

@import '@splidejs/splide/src/sass/core/object/modifiers/draggable';
@import '@splidejs/splide/src/sass/core/object/modifiers/fade';
//@import '@splidejs/splide/src/sass/core/object/modifiers/rtl';
//@import '@splidejs/splide/src/sass/core/object/modifiers/ttb';

/* Splide Default Style Files */
@import '@splidejs/splide/src/sass/themes/default/foundation/variables';

//@import '@splidejs/splide/src/sass/themes/default/object/objects/arrow'; --> custom
//@import '@splidejs/splide/src/sass/themes/default/object/objects/pagination'; --> custom
//@import '@splidejs/splide/src/sass/themes/default/object/objects/progress';

@import '@splidejs/splide/src/sass/themes/default/object/modifiers/nav';
//@import '@splidejs/splide/src/sass/themes/default/object/modifiers/rtl';
//@import '@splidejs/splide/src/sass/themes/default/object/modifiers/ttb';

.element-header {
    margin-bottom: 20px;
}

.image-splide .splide__track {
    z-index: 1;

    .image {
        overflow: hidden;

        img {
            width: 100%;
            transform: scale(1);
            transition: transform $transition-time $transition-ease;
        }

        .figure-caption {
            display: none;
        }

        &:hover,
        &:focus {
            img {
                transform: scale(1.05);
                transition: transform $transition-time $transition-ease;
            }
        }
    }
}

.css-arrow-slider {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &:before {
        content: '';
        position: absolute;
        height: 80%;
        width: 80%;
        border-top: 1px solid $black;
        border-left: 1px solid $black;
        top: 55%;
        left: 55%;
        transform: translate(-50%, -50%);
    }
}

.splide {
    &.image-splide {
        padding-left: 10px;
        padding-right: 10px;

        @include media-breakpoint-up (lg) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    &__arrows {
        position:absolute;
        height: 40px;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-17px);
    }

    &__arrow {
        @extend .css-arrow-slider;
        display: block;
        position: absolute;
        left: auto;
        right: auto;
        width: 25px;
        height: 25px;
        border: 0;
        padding: 0;
        background: transparent;
        transition: fill $transition-time $transition-ease;

        @include media-breakpoint-up (lg) {
            width: 35px;
            height: 35px;
        }

        &--prev {
            transform: rotate(-45deg);
            left: -15px;

            @include media-breakpoint-up (lg) {
                left: 0;
            }
        }

        &--next {
            transform: rotate(135deg);
            right: -15px;

            @include media-breakpoint-up (lg) {
                right: 0;
            }
        }

        &:hover {
            cursor: pointer;

            &:not(:disabled) {
                fill: $secondary;
            }
        }

        &:focus {
            outline: none;

            &:not(:disabled) {
                fill: $secondary;
            }
        }
    }
}


