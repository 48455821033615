@import "variables";

// Navbar
html.main-navbar-open {
    overflow: hidden;
}

.menu-open {
    .navbar {
        .navbar-wrapper {
            visibility: visible;
            transform: translateY(0);
            transition: transform $transition-time $transition-ease;
            overflow-y: scroll;
        }
    }
}

.navbar {
    .navbar-wrapper {
        background-color: $white;
        visibility: hidden;
        transform: translateY(100%);
        transition: transform $transition-time $transition-ease;
        z-index: $zindex-fixed;

        @include media-breakpoint-up (lg) {
            visibility: visible;
            top: 0;
            transform: translateY(0);
            height: auto;
            padding: 0 20px;
        }
    }

    .navbar-collapse {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0;
        background-color: $white;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        .w4-navigation-second-part {
            margin-bottom: 100px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

    }

    .navbar-nav {
        .nav-link {
            font-family: $font-family-sans-serif;
            font-size: $font-size-mobile-nav;
            line-height: 1;
            padding: 12px 0;
            text-align: center;
            background: $navbar-mobile-bg-color-first-level;
        }

        .nav-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 15px;

            @include media-breakpoint-up(xl) {
                padding-bottom: 20px;
            }
        }

        .dropdown {
            position: static;

            .dropdown-menu {
                padding: 0;
                display: block;

                ul {
                    list-style-type: none;
                    padding: 5px 0 15px 0;

                    .nav-link {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include media-breakpoint-up(lg) {
                            content: initial;
                        }
                    }

                    .dropdown-menu-item {
                        &:after {
                            content: '';
                            display: block;
                            margin: 0 auto;
                            width: 66px;
                            border-bottom: 1px solid $navbar-mobile-line-color;

                            @include media-breakpoint-up(lg) {
                                content: initial;
                            }
                        }

                        &:last-child:after {
                            border-bottom: 0;
                        }

                        .nav-link {
                            font-size: $font-size-mobile-nav-second;
                            background: transparent;
                            padding: 9px 0;

                            &.active {
                                color: $white;
                                background: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.navbar-expand-lg .navbar-collapse {
            background: $white;
        }

        &.navbar-expand-lg {
            .navbar-nav {
                align-items: flex-end;

                .nav-item {
                    &.active {
                        .nav-link {
                            &::after {
                                right: 0;
                                transition: right $transition-time $transition-ease;
                            }
                        }

                        .dropdown-menu-item {
                            .nav-link {
                                &::after {
                                    right: 100%;
                                }
                            }
                        }
                    }

                    .nav-link {
                        line-height: 1.2;
                        font-size: $font-size-nav;
                        position: relative;
                        background-color: $white;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 10px;
                            height: 2px;
                            left: 0;
                            right: 100%;
                            background-color: $dark;
                            transition: right $transition-time $transition-ease;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $black;

                            &::after {
                                right: 0;
                                transition: right $transition-time $transition-ease;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    padding: 0 30px;
                    background: $background-stripe;
                    border: 0;
                    display: none;

                    &.show {
                        left: auto;
                        display: block;
                        position: fixed;
                        min-width: inherit;

                        ul {
                            padding: 16px 0;
                        }

                        .dropdown-menu-item {
                            a {
                                &.nav-link {
                                    width: 100%;
                                    height: 100%;
                                    padding: 9px 0;
                                    position: relative;
                                    color: $white;
                                    font-size: 1.063rem;
                                    line-height: 1;
                                    justify-content: flex-start;

                                    &::after {
                                        display: none;
                                    }
                                }

                                &:hover,
                                &:focus,
                                &:active,
                                &.active {
                                    background: transparent;
                                    text-decoration: underline;

                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-item {
            .fa-chevron-down {
                position: relative;
                top: -1px;
                margin-left: 0.5rem;
            }
        }
    }
}

@keyframes dropdown-fade {
    from {
        opacity: 0;
        transform: translate(-50%, -1rem);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

/*IE 11 Browserhack to fix Submenu background issue*/
@media all and (-ms-high-contrast:none) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        min-width: 600px;
    }
}
