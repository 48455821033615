@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $gray-400
);
$theme-colors: map-remove($theme-colors, "info", "warning");

//here because in variables we don't have bootstraps function add
$input-height: add($input-line-height * 1rem, $input-padding-top + $input-padding-bottom);
$input-height-lg: add($input-line-height * 1rem, $input-padding-top-lg + $input-padding-bottom-lg);

//Bootstrap Optional
//@import "bootstrap/scss/forms";--> see custom styles below
//@import "bootstrap/scss/custom-forms"; --> different custom solution below!

//Custom Styles
@import "mixins";

//
// Textual form controls
//

.form-control {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-top $input-padding-x $input-padding-bottom;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: 0;

    .frame-background-light & {
        background-color: $custom-control-indicator-bg;
    }

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include transition($input-transition);

    @include media-breakpoint-up(lg) {
        padding: $input-padding-top-lg $input-padding-x-lg $input-padding-bottom-lg;
        height: $input-height-lg;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
    }

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        outline: 0;
        box-shadow: none;
    }

    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &[type="file"] {
        padding-top: $input-padding-bottom;

        @include media-breakpoint-up(lg) {
            padding-top: $input-padding-bottom-lg;
        }
    }
}

textarea.form-control {
    height: auto;
    min-height: 9rem;
    resize: vertical;
}

// Form groups

.form-group {
    margin-bottom: $form-group-margin-bottom;
}

.form-set {
    margin-bottom: $form-group-margin-bottom;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$form-grid-gutter-width / 2;
    margin-left: -$form-grid-gutter-width / 2;

    > .col,
    > [class*="col-"] {
        padding-right: $form-grid-gutter-width / 2;
        padding-left: $form-grid-gutter-width / 2;
    }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@each $state, $data in $form-validation-states {
    @include custom-form-validation-state($state, map-get($data, color), map-get($data, icon));
}


// Floating Labels

label {
    font-size: $font-size-sm;
    line-height: $input-line-height;
}

.form-group-floating {
    position: relative;

    label {
        position: absolute;
        left: $input-padding-x;
        top: $input-padding-top;
        color: $input-placeholder-color;
        transition: all 0.15s ease-in-out;

        @include media-breakpoint-up(lg) {
            left: $input-padding-x-lg;
            top: $input-padding-top-lg;
        }
    }

    input:focus ~ label,
    textarea:focus ~ label,
    &.input-filled > label {
        font-size: 0.625rem;
        top: 0.25rem;
        color: $input-color;

        @include media-breakpoint-up(lg) {
            top: 0.5rem;
        }
    }
}

// Custom Checkboxes and Radiobuttons

.custom-control {
    position: relative;
    z-index: 1;

    .custom-control-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        z-index: -1; // Put the input behind the label so it doesn't overlay text
    }

    .custom-control-label {
        cursor: pointer;
        user-select: none;
        position: relative;
        padding-left: $custom-control-gutter + $custom-control-indicator-size;

        &::before {
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            margin-top: -($custom-control-indicator-size / 2);
            left: 0;
            height: $custom-control-indicator-size;
            width: $custom-control-indicator-size;
            background-color: $custom-control-indicator-bg;

            .frame-background-dark & {
                background-color: $input-bg;
            }
        }

        &::after {
            position: absolute;
            display: none;
            content: "";
            top: 50%;
            margin-top: -6px;
        }
    }

    &:hover .custom-control-label::before {
        background-color: $custom-control-hover-indicator-bg;

        .frame-background-dark & {
            background-color: $input-focus-bg;
        }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        background-color: $custom-control-hover-indicator-bg;
        .frame-background-dark & {
            background-color: $input-focus-bg;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $custom-control-indicator-checked-bg;
        .frame-background-dark & {
            background-color: $custom-control-indicator-checked-bg;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        display: block;
    }

    &.custom-control-checkbox {

        .custom-control-label {
            &::before {
                border-radius: $custom-checkbox-indicator-border-radius;
            }

            &::after {
                width: 6px;
                height: 12px;
                left: 9px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

    }

    &.custom-control-radio {
        .custom-control-label {
            &::before {
                border-radius: $custom-radio-indicator-border-radius;
            }
            &::after {
                width: 12px;
                height: 12px;
                left: 6px;
                background-color: $white;
                border-radius: $custom-radio-indicator-border-radius;
            }
        }
    }
}

label.form-group-label {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    margin-bottom: $form-group-margin-bottom;
}

.input.checkbox {
    .inputs-list {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
    }


    input {
        margin-right: 5px;
    }
}

.custom-control {
    a {
        text-decoration: underline;
    }

    &.font-xs {
        .custom-control-label {
            font-size: $font-size-sm;
            line-height: $line-height-base;
        }
    }

    &.custom-control-block {
        width: 100%;
        padding-left: 0;
        margin-bottom: $form-group-margin-bottom;

        .custom-control-label {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: $input-height;
            height: $input-height;
            background-color: $input-bg;
            padding: $custom-control-block-padding $custom-control-block-padding $custom-control-block-padding $custom-control-block-padding + $custom-control-gutter + $custom-control-indicator-size;
            border-radius: $input-border-radius;

            @include media-breakpoint-up(lg) {
                min-height: $input-height-lg;
                height: $input-height-lg;
            }

            &:hover {
                background-color: $input-focus-bg;
            }

            &::before {
                left: $custom-control-block-padding;
            }
        }

        &.custom-control-checkbox {
            .custom-control-label {
                &::after {
                    left: $custom-control-block-padding + 0.5625rem;
                }
            }
        }

        &.custom-control-radio {
            .custom-control-label {
                &::after {
                    left: $custom-control-block-padding + 0.375rem;
                }
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            background-color: $primary;
            color: $white;
        }

        .custom-control-input:focus:not(:checked) ~ .custom-control-label {
            background-color: $input-focus-bg;
        }
    }
}

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
    display: inline-block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-top $input-padding-x $input-padding-bottom;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    vertical-align: middle;
    background: $input-bg $custom-select-background;
    border: 0;
    @include border-radius($input-border-radius, 0);
    appearance: none;

    @include media-breakpoint-up(lg) {
        padding: $input-padding-top-lg $input-padding-x-lg $input-padding-bottom-lg;
        height: $input-height-lg;
    }

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        outline: 0;
        box-shadow: none;

        &::-ms-value {
            // For visual consistency with other platforms/browsers,
            // suppress the default white text on blue background highlight given to
            // the selected option text when the (still closed) <select> receives focus
            // in IE and (under certain conditions) Edge.
            // See https://github.com/twbs/bootstrap/issues/19398.
            color: $input-color;
            background-color: $input-bg;
        }
    }

    // Hides the default caret in IE11
    &::-ms-expand {
        display: none;
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $custom-select-color;
    }
}

.form-group-select {
    position: relative;
    label {
        position: absolute;
        left: $input-padding-x;
        top: 0.25rem;
        color: $input-color;
        font-size: 0.625rem;

        @include media-breakpoint-up(lg) {
            left: $input-padding-x-lg;
            top: 0.5rem;
        }
    }
}

.help-block {
    font-size: 80%;
    line-height: $line-height-base;
    display: inline-block;
    color: red;
}

.form-ce {
    padding-left: 0;
    padding-right: 0;

    p {
        line-height: 1.3;
    }
}

@include media-breakpoint-up(lg) {
    #contactform-48-fieldset-contactdata {
        .form-row .col-12 {
            &:nth-child(4) {
                order: 3;
                transform: translateY(-100%);

                span.error {
                    position: absolute;
                }

            }

            &:nth-child(5) {
                order:2;
            }
        }
    }
}